export const Sluzby = (props) => {
    return (
      <div id='sluzby' className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
          
            <h2>Hlavní Služby</h2>
            <p>
              Naše komplexní služby se opírají o dvě hlavní oblasti, na které se zaměřujeme.
              <br/>
              Samozřejmostí u každé z našich služeb je individuální přizpůsobení potřebám klienta.
              <br/><br/>
              Pro <i>nezávaznou</i> konzultaci nás neváhejte kontaktovat.
            </p>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-6'>
                    {' '}
                    <i className={d.icon}></i>
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                      <p>{d.detail}</p>
                      <br/>
                      <p><center><img width="300" src={d.img} className="img-responsive" alt={d.img_alt} />{" "}</center></p>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }