//import { useState } from 'react'
//import emailjs from 'emailjs-com'

/*
const initialState = {
  name: '',
  email: '',
  message: '',
}
*/

export const Contact = (props) => {
  //const [{ name, email, message }, setState] = useState(initialState)

  /*
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  */

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Kontakty</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Adresa
                </span>
                <div>
                  <p>{props.data ? props.data.address_name : 'loading'}</p>
                  <p>{props.data ? props.data.address_street : 'loading'}</p>
                  <p>{props.data ? props.data.address_city : 'loading'}</p>
                </div>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefon
                </span>{' '}
                <div>
                  <p>{props.data ? props.data.phone : 'loading'}</p>
                </div>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                <div>
                  <p>{props.data ? props.data.email : 'loading'}</p>
                </div>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-search'></i> IČO
                </span>{' '}
                <div>
                  <p>{props.data ? props.data.ico : 'loading'}</p>
                </div>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-inbox'></i> Bankovní kontakt
                </span>{' '}
                <div>
                  <p>{props.data ? props.data.banka : 'loading'}</p>
                </div>
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; SinguliT s.r.o.
          </p>
        </div>
      </div>
    </div>
  )
}
