import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
//import { Services } from "./components/services";
import { Sluzby } from "./components/sluzby";
//import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";
//import { Aktuality } from "./components/aktuality";
//import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


//<Services data={landingPageData.Services} />
//<Features data={landingPageData.Features} />
//<Gallery data={landingPageData.Gallery}/>
//<Testimonials data={landingPageData.Testimonials} />
//<Team data={landingPageData.Team} />

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      
      <Sluzby data={landingPageData.Sluzby} />
      <Features data={landingPageData.Features} />
      
      
      {/*<Aktuality data={landingPageData.Aktuality} />*/}
      <About data={landingPageData.About} />
      
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
