export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/_7086ca14-06a5-4767-bb91-43d986a7a983.jpg" className="img-responsive" alt="Outsouring, správa sítě, odborníci v IT, spokojení klienti, inovace, kokurenceschopnost" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>O nás</h2>

              {/*
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              */}



                <h3>Kdo jsme:</h3>
                <ul>
                    <li>Jsme odborníci v oblasti IT</li>
                    <li>Více než dvacet let zkušeností v oboru</li>
                    <li>Nabízíme komplexní portfolio služeb</li>
                </ul>
                

                {/* 
                <h3>Co nabízíme:</h3>
                <p>
                  <h4>Outsourcingové služby:</h4>
                  <ul>
                      <li>Snížení nákladů</li>
                      <li>Zvýšení efektivity</li>
                  </ul>

                  <h4>Vývoj software:</h4>
                  <ul>
                      <li>Zakázkový software</li>
                      <li>Přizpůsobený vašim potřebám</li>
                      <li>Vývoj mobilních aplikací a webových stránek</li>
                  </ul>

                  <h4>Správa sítí:</h4>
                  <ul>
                      <li>Stabilita a bezpečnost IT systémů</li>
                      <li>Rychlá reakce na výzvy a problémy</li>
                      <li>Neustálá monitorace a údržba sítí</li>
                  </ul>
                  </p>
                */}


                <h3>Naše hodnoty:</h3>
                  <ul>
                    <li>Inovace a moderní technologie</li>
                    <li>Strategické plánování IT pro dosažení obchodních cílů</li>
                    <li>Flexibilita a přizpůsobení potřebám klientů</li>
                  </ul>

                <h3>Důvěra a spokojení klienti:</h3>
                  <ul>
                    <li>Rozsáhlé portfolio spokojených zákazníků z různých odvětví</li>
                    <li>Investice do vzdělávání našich zaměstnanců</li>
                  </ul>

                <h3>Proč zvolit nás:</h3>
                  <ul>
                      <li>Rychlá reakce a řešení IT problémů</li>
                      <li>Spolupráce s nejlepšími technologickými partnery</li>
                      <li>Dlouhodobý a otevřený vztah s klienty</li>
                  </ul>
                
                <h3>Inovace a konkurenceschopnost:</h3>
                  <ul>
                    <li>Neustále sledujeme nové trendy v IT odvětví</li>
                    <li>Inovativní řešení pro zvýšení produktivity a konkurenceschopnosti</li>
                  </ul>

                
                <p>Vaše firma je s námi na správné cestě k úspěchu v digitálním světě!</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
