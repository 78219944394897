export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
        <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
            <a className='navbar-brand page-scroll' href='#page-top'>
              <img src="img/logs/SinguliT_logo_light_transparent (1).png" alt="Logo" width="150" />
            </a> 
            <div className='navbar-brand page-scroll'>s.r.o.</div>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li>
              <a href='#about' className='page-scroll'>
                O nás
              </a>
            </li>


            <li>
              <a href='#sluzby' className='page-scroll'>
                Služby
              </a>
            </li>

          {/*
            <li>
              <a href='#aktuality' className='page-scroll'>
                Aktuality
              </a>
            </li>

          */}

            <li>
              <a href='#contact' className='page-scroll'>
                Kontakty
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}
